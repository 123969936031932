import callGpt from './../assets/images/callgpt.png';
import wordRushLogo from './../assets/images/word-rush-logo.png';
import wordRush_1 from './../assets/images/word-rush-1.jpg';
import wordRush_2 from './../assets/images/word-rush-2.jpg';
import mindtickleLogo from './../assets/images/mindtickle-logo.png';
import memtiles from './../assets/images/memtiles.png';
import wordFinder from './../assets/images/word-finder.png';
import superheroes from './../assets/images/superheroes.jpg';
import netflixCloneLogo from './../assets/images/netflix-logo.png';
import netflixClone from './../assets/images/netflix-clone.jpg';
import indianStatesLogo from './../assets/images/indian-states-logo.png';
import indianStates_1 from './../assets/images/indian-states-1.jpg';
import indianStates_2 from './../assets/images/indian-states-2.jpg';
import androidLogo from './../assets/images/android-logo.png';
import voiceBasedLED from './../assets/images/voice-based-leds.jpg';
import codepenLogo from './../assets/images/codepen-logo.png';
import buttonAnim from './../assets/images/button-anim.gif';
import reviewAnim from './../assets/images/review-anim.gif';
import tetrisAnim from './../assets/images/tetris anim.gif';
import android from './../assets/images/icons/android.svg';
import angular from './../assets/images/icons/angular.svg';
import aws from './../assets/images/icons/aws.svg';
import css from './../assets/images/icons/css.svg';
import git from './../assets/images/icons/git.svg';
import html from './../assets/images/icons/html.svg';
import ionic from './../assets/images/icons/ionic.svg';
import java from './../assets/images/icons/java.svg';
import javascript from './../assets/images/icons/javascript.svg';
import jquery from './../assets/images/icons/jquery.svg';
import memtiles_logo from './../assets/images/icons/memtiles.svg';
import nodejs from './../assets/images/icons/nodejs.svg';
import polymer from './../assets/images/icons/polymer.svg';
import react from './../assets/images/icons/react.svg';
import sass from './../assets/images/icons/sass.svg';
import socketio from './../assets/images/icons/socketio.svg';
import typescript from './../assets/images/icons/typescript.svg';
import vue from './../assets/images/icons/vue.svg';
import wordpress from './../assets/images/icons/wordpress.svg';


export default {
    techLogos: [
        html, css, javascript,
        vue, angular, polymer,
        typescript, jquery, java,
        wordpress, nodejs, aws,
        git, ionic, android,
        sass, socketio
    ],
    feedData: [
        {
            title: 'call_gpt', logo: mindtickleLogo, location: 'AWS servers', image: callGpt,
            likedBy: ['OpenAI', 'React', 'Python', 'AWS', 'Sass', 'langchain'],
            comment: 'An OpenAI and LangChain based chat system for sales team. Built as part of a hackathon project at Mindtickle.'
        },
        {
            title: 'memtiles', logo: memtiles_logo, location: 'Netlify', image: memtiles,
            likedBy: ['Figma', 'React', 'Sass'],
            comment: 'A simple recreation of the classic memory tile game with modern UI.'
        },
        {
            title: 'word_finder', logo: react, location: 'localhost', image: wordFinder,
            likedBy: ['React', 'Sass', 'OCR'],
            comment: 'A Windows 98 themed word matching app with OCR support.'
        },
        {
            title: 'word_rush', logo: wordRushLogo, location: 'Google Play', image: wordRush_1,
            likedBy: ['Angular', 'Node.js', 'AWS', 'Ionic', 'Android', 'Sass', 'socket.io'],
            comment: 'An Android game where you read word definition(s) and guess the word.'
        },
        {
            title: 'netflix_clone', logo: netflixCloneLogo, location: 'Netlify', image: netflixClone,
            likedBy: ['React', 'sass', 'TMDb'],
            comment: 'A static redesign of Netflix clone done using React. Used TMDb for movie data.'
        },
        {
            title: 'codepen', logo: codepenLogo, location: 'Codepen', image: tetrisAnim,
            likedBy: ['javascript', 'html', 'sass'],
            comment: 'Tetris game mock with an unlucky twist at the end.'
        },
        {
            title: 'word_rush', logo: wordRushLogo, location: 'Google Play', image: wordRush_2,
            likedBy: ['socket.io', 'Ionic', 'Angular', 'Node.js', 'AWS', 'Android', 'Sass'],
            comment: 'Also supports multiplayer mode. Implemented using socket.io and NodeJs.'
        },
        {
            title: 'codepen', logo: codepenLogo, location: 'Codepen', image: superheroes,
            likedBy: ['html', 'sass'],
            comment: 'Simple Codepen involving hover effects and card layout'
        },
        {
            title: 'indian_states', logo: indianStatesLogo, location: 'Google Play', image: indianStates_1,
            likedBy: ['Ionic', 'Angular', 'Node.js', 'AWS', 'Android', 'Sass'],
            comment: 'A general knowledge Android app showing information on all Indian states and Union Territories'
        },
        {
            title: 'indian_states', logo: indianStatesLogo, location: 'Google Play', image: indianStates_2,
            likedBy: ['AWS', 'Ionic', 'Angular', 'Node.js', 'Android', 'Sass'],
            comment: 'Also shows an image carousel along with the states data.'
        },
        {
            title: 'codepen', logo: codepenLogo, location: 'Codepen', image: reviewAnim,
            likedBy: ['sass', 'html'],
            comment: 'A simple animation on hover of review button.'
        },
        {
            title: 'voice_based_LEDs', logo: androidLogo, location: 'somewhere on Earth', image: voiceBasedLED,
            likedBy: ['Android', 'Java', 'C', 'Arduino'],
            comment: 'UI showing commands to turn on/off LEDs. Also supports voice commands.'
        },
        {
            title: 'codepen', logo: codepenLogo, location: 'Codepen', image: buttonAnim,
            likedBy: ['html', 'sass'],
            comment: 'A copy-button click animation.'
        }
    ]
};