import React from 'react';
import appStyles from './../App.module.scss';
import styles from './ProjectsSection.module.scss';
import projectsData from './projectsData';
import { ReactComponent as OpenLink } from './../assets/images/icons/open-tab.svg';

const ProjectsSection = () => {
    return (
        <div id="projects" className={`section ${styles['projects']}`}>
            <div className={`${appStyles['container']} ${styles['projects-container']}`}>
                <div className={styles['project-cards']}>
                    {
                        projectsData.projects.map((project, index) => (
                            <div className={styles['project-card']} key={index}>
                                <div className={styles['shine']}></div>
                                { project.link &&
                                    <a className={styles['open-link']} href={project.link} title="open">
                                        <OpenLink />
                                    </a>
                                }
                                <div className={styles['project-title']}>{project.title}</div>
                                <div className={styles['project-description']}>{project.description}</div>
                                <div className={styles['tech-icons']}>
                                    {
                                        project.icons.map(({ icon: Icon, title, link }, index) => (
                                            <a href={link} key={index} >
                                                <Icon title={title} />
                                            </a>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default ProjectsSection;