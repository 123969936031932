const fireGaEvent = () => {
    if (window.location.origin.indexOf('netlify') > -1) {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-6CQ5Z94J3D');
        console.log('GA event fired.');
    }
}

export default fireGaEvent;