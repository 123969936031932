import { ReactComponent as android } from './../assets/images/icons/android.svg';
import { ReactComponent as angular } from './../assets/images/icons/angular.svg';
import { ReactComponent as arduino } from './../assets/images/icons/arduino.svg';
import { ReactComponent as aws } from './../assets/images/icons/aws.svg';
import { ReactComponent as c } from './../assets/images/icons/c.svg';
import { ReactComponent as figma } from './../assets/images/icons/figma.svg';
import { ReactComponent as ionic } from './../assets/images/icons/ionic.svg';
import { ReactComponent as java } from './../assets/images/icons/java.svg';
import { ReactComponent as nodejs } from './../assets/images/icons/nodejs.svg';
import { ReactComponent as react } from './../assets/images/icons/react.svg';
import { ReactComponent as sass } from './../assets/images/icons/sass.svg';
import { ReactComponent as socketio } from './../assets/images/icons/socketio.svg';

export default {
    projects: [
        {
            title: 'Word Matcher',
            link: '',
            description: 'A Windows 98 themed word matching app with OCR support.',
            icons: [
                { icon: react, title: 'React', link: 'https://developer.mozilla.org/en-us/docs/web/html' },
                { icon: sass, title: 'Sass', link: 'https://sass-lang.com/' },
                { icon: figma, title: 'Figma', link: 'https://www.figma.com/' }
            ]
        },
        {
            title: 'Memtiles',
            link: 'http://memtiles.netlify.app/',
            description: 'A simple recreation of the classic memory tile game with modern UI.',
            icons: [
                { icon: react, title: 'React', link: 'https://developer.mozilla.org/en-us/docs/web/html' },
                { icon: sass, title: 'Sass', link: 'https://sass-lang.com/' },
                { icon: figma, title: 'Figma', link: 'https://www.figma.com/' }
            ]
        },
        {
            title: 'Word Rush',
            link: 'https://play.google.com/store/apps/details?id=com.vacoders.wordrush',
            description: 'A multiplayer word guessing game developed for Android. The objective of the game is to guess words by reading it\'s dictionary definitions before the timer runs out.',
            icons: [
                { icon: angular, title: 'Angular', link: 'https://angular.io/' },
                { icon: nodejs, title: 'Node.js', link: 'https://nodejs.org/en/' },
                { icon: aws, title: 'AWS', link: 'https://aws.amazon.com/' },
                { icon: ionic, title: 'Ionic', link: 'https://ionicframework.com/' },
                { icon: android, title: 'Android', link: 'https://www.android.com/' },
                { icon: sass, title: 'Sass', link: 'https://sass-lang.com/' },
                { icon: socketio, title: 'socket.io', link: 'https://socket.io/' }
            ]
        },
        {
            title: 'Indian States',
            link: 'https://play.google.com/store/apps/details?id=com.smartly',
            description: 'A general knowledge android app showing various details of Indian states and union territories.',
            icons: [
                { icon: angular, title: 'Angular', link: 'https://angular.io/' },
                { icon: nodejs, title: 'Node.js', link: 'https://nodejs.org/en/' },
                { icon: aws, title: 'AWS', link: 'https://aws.amazon.com/' },
                { icon: ionic, title: 'Ionic', link: 'https://ionicframework.com/' },
                { icon: android, title: 'Android', link: 'https://www.android.com/' },
                { icon: sass, title: 'Sass', link: 'https://sass-lang.com/' }
            ]
        },
        {
            title: 'Netflix Clone',
            link: 'https://netflix-revamp.netlify.app/',
            description: 'A Netflix redesign using React. The site is static without any functionality and shows dummy data.',
            icons: [
                { icon: react, title: 'React', link: 'https://developer.mozilla.org/en-us/docs/web/html' },
                { icon: sass, title: 'Sass', link: 'https://sass-lang.com/' }
            ]
        },
        {
            title: 'Voice Based LEDs',
            link: '',
            description: 'Tried my hands on the Arduino board to wirelessly control LEDs connected to a breadboard with voice command support. The board was connected to the Android app via Bluetooth HC-05.',
            icons: [
                { icon: android, title: 'Android', link: 'https://www.android.com/' },
                { icon: java, title: 'Java', link: 'https://www.oracle.com/java/' },
                { icon: c, title: 'C', link: 'https://en.wikipedia.org/wiki/C_(programming_language)' },
                { icon: arduino, title: 'Arduino', link: 'https://www.arduino.cc/' }
            ]
        }
    ]
};