
import countApi from 'countapi-js';
const namespace = 'am-pageview-counter-namespace', key = 'pageview';

// Create new counter
/* countApi.create({ key, namespace, enable_reset: 1, update_lowerbound: 0 }).then((result) => {
    console.log(result);
}); */

const getPageviewCount = () => {
    if (sessionStorage.getItem('newUser') === null) {
        countApi.update(namespace, key, 1).then((result) => {
            sessionStorage.setItem('newUser', "true");
            updatePageViewInDOM(result.value);
        });
    } else {
        countApi.get(namespace, key).then(result => updatePageViewInDOM(result.value));
    }
}

const updatePageViewInDOM = (pageview) => {
    if (document.querySelector('#pageview')) {
        document.querySelector('#pageview').innerText = pageview;
    }
}

export default getPageviewCount;