import React from 'react';
import styles from './InstagramFeed.module.scss';

const InstagramFeed = (props) => {
    return (
        <div className={styles['insta-feed']}>
            { props.feedData.map((item, index) => {
                return (
                    <div className={styles['feed-item']} key={index}>
                        <div className={styles['feed-item-author']}>
                            <div className={styles['author-image']}
                                style={{ backgroundImage: `url(${item.logo})` }}></div>
                            <div className={styles['author-details']}>
                                <div className={styles['author-name']}>{item.title}</div>
                                <div className={styles['author-location']}>{item.location}</div>
                            </div>
                        </div>
                        <div className={styles['feed-image']}>
                            <img src={item.image} alt="feed" loading="lazy" key={index} />
                        </div>
                        <div className={styles['feed-actions']}></div>
                        <div className={styles['feed-details']}>
                            <div className={styles['liked-by']}>
                                Liked by <strong>{item.likedBy[0]}</strong> and <strong>{item.likedBy.length * (Math.round(Math.random() * 100)) || 276} others</strong>
                            </div>
                            <div className={styles['comment']}>
                                <strong>{item.title}</strong> {item.comment}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div >

    );
}

export default InstagramFeed;