import React from 'react';
import appStyles from './../App.module.scss';
import styles from './NavigationBar.module.scss';
import navbarData from './navbarData';

const NavigationBar = (props) => {
    return (
        <div id="NavigationBar" className={props.navbarOpen ? `${styles['navbar']} ${styles['show']}` : styles['navbar']}>
            <div className={`${appStyles['container']}  ${styles['navigation-container']}`}>
                <div className={styles['logo-container']}>
                    <a href="#home">
                        <img src={navbarData.logo} alt="Logo" />
                    </a>
                    <div className={styles['mobile-nav-button']} onClick={props.toggleNavbar}>≡</div>
                </div>
                <nav role="navigation" className={styles['nav-menu']}>
                    {
                        navbarData.navMenuItems.map(({ title, link, logo: MenuItemLogo }, index) => {
                            if (!MenuItemLogo) {
                                return <a href={`#${link}`} className={props.activeSection === link ? styles['active'] : ''} onClick={props.toggleNavbar} key={index}>{title}</a>
                            } else {
                                return <a href={link} rel="noopener noreferrer" key={index}><MenuItemLogo title={title} /></a>
                            }
                        })
                    }
                </nav>
            </div>
        </div>
    );
}

export default NavigationBar;