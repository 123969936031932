import React from 'react';
import NavigationBar from './components/NavigationBar';
import HomeSection from './components/HomeSection';
import ProjectsSection from './components/ProjectsSection';
import Background from './components/Background';
import AboutSection from './components/AboutSection';
import fireGaEvent from './components/ga';

fireGaEvent();

class App extends React.Component {
  state = {
    navbarOpen: false,
    activeSection: 'home'
  };

  componentDidMount() {
    this.initIntersectionObserver();
  }

  render() {
    return (
      <div>
        <Background />
        <NavigationBar navbarOpen={this.state.navbarOpen} activeSection={this.state.activeSection} toggleNavbar={this.toggleNavbar} />
        <HomeSection />
        <ProjectsSection />
        <AboutSection />
        <div id='overlay' onClick={this.toggleNavbar} style={{ display: this.state.navbarOpen ? 'block' : 'none' }} ></div>
      </div>
    );
  }

  toggleNavbar = () => {
    if (window.innerWidth < 767) {
      this.setState({
        navbarOpen: !this.state.navbarOpen
      });
    }
  }

  initIntersectionObserver() {
    const myIntersectionObserver = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          this.setState({
            activeSection: entry.target.id
          });
        }
      }
    }, { root: null, threshold: 0.5 });
    document.querySelectorAll('#root .section').forEach((section) => {
      myIntersectionObserver.observe(section);
    });
  }
}

export default App;
