import React from 'react';
import appStyles from './../App.module.scss';
import styles from './AboutSection.module.scss';
import InstagramMock from './InstagramMock';
import navbarData from './navbarData';

const AboutSection = () => {
    return (
        <div id="about" className={`section ${styles['about-section']}`}>
            <div className={`${appStyles['container']} ${styles['about-container']}`}>
                <InstagramMock />
                <div className={styles['footer-nav-wrap']}>
                    <div className={styles['logo-wrapper']}>
                        <img src={navbarData.logo} alt="Logo" />
                    </div>
                    <div className={styles['footer-nav-menu']}>
                        {
                            navbarData.navMenuItems.map(({ title, link, logo: MenuItemLogo }, index) => {
                                if (MenuItemLogo) {
                                    return (
                                        <a href={link} target='_blank' rel="noopener noreferrer" key={index}>
                                            <MenuItemLogo title={title} />
                                        </a>
                                    )
                                } else
                                    return null;
                            })
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AboutSection;