import React, { useState } from 'react';
import InstagramFeed from './InstagramFeed';
import styles from './InstagramMock.module.scss';
import instagramMockData from './InstagramMockData';
import profilePic from './../assets/images/profile-pic-1.png';
import getPageviewCount from './pageViewCount';

getPageviewCount();

const InstagramMock = () => {
    const [techSectionActive, setTechSectionActive] = useState(false);
    const date = new Date();
    const time = date.getHours() + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    return (
        <div className={styles['instagram-mock-wrapper']}>
            <div className={styles['mobile-mockup']}>
                <div className={styles['status-bar']}>
                    <span className={styles['time']}>{time}</span>
                    <span className={styles['right']}>
                        <span className={styles['wifi-signal']}></span>
                        <span className={styles['batter-indicator']}></span>
                    </span>
                </div>
            </div>
            <div className={styles['content']}>
                <div className={styles['tool-bar']}>
                    <span className={styles['back-icon']}></span>
                    <span>Profile</span>
                    <span className={styles['hamburger-icon']}></span>
                </div>
                <div className={styles['profile-section']}>
                    <div className={styles['profile-pic-wrap']}>
                        <img src={profilePic} alt="" />
                    </div>
                    <div className={styles['stats']}>
                        <span>{date.getFullYear() - 2017 + (date.getMonth() <= 5 ? -1 : 0) + '+ yrs'}</span>
                        <span>Experience</span>
                    </div>
                    <div className={styles['stats']}>
                        <span id="pageview">-</span>
                        <span>Page Views</span>
                    </div>
                </div>
                <div className={styles['bio-section']}>
                    <div className={styles['name']}>Arjun Mudhaliyar</div>
                    <div className={styles['bio']}>
                        <span>Software Developer</span>
                        <span>Technology, Football, FIFA</span>
                        <span>Berlin, Germany</span>
                    </div>
                </div>
                <div className={styles['layout-selection']}>
                    <span className={!techSectionActive ? styles['active'] : null} onClick={setTechSectionActive.bind(this, false)}>
                        Projects
                    </span>
                    <span className={techSectionActive ? styles['active'] : null} onClick={setTechSectionActive.bind(this, true)}>
                        Tech
                    </span>
                </div>
                <div className={styles['posts-section']} style={{ height: techSectionActive ? "570px" : null }}>
                    <div className={styles['project']} style={{ marginLeft: techSectionActive ? "-100%" : "0" }}>
                        <InstagramFeed feedData={instagramMockData.feedData} />
                    </div>
                    <div className={styles['tech']}>
                        {instagramMockData.techLogos.map((image, index) => <img src={image} alt="feed" key={index} />)}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default InstagramMock;