import Logo from './../assets/images/icons/logo.png';
import { ReactComponent as TwitterLogo } from './../assets/images/icons/twitter.svg';
import { ReactComponent as LinkedInLogo } from './../assets/images/icons/linkedIn.svg';
import { ReactComponent as GitHubLogo } from './../assets/images/icons/github.svg';

export default {
    logo: Logo,
    navMenuItems: [
        { title: 'Home', link: 'home' },
        { title: 'Projects', link: 'projects' },
        { title: 'About', link: 'about' },
        { title: 'GitHub', link: 'https://github.com/ArjunAce/', logo: GitHubLogo },
        { title: 'LinkedIn', link: 'https://in.linkedin.com/in/arjun-mudhaliyar/', logo: LinkedInLogo },
        { title: 'Twitter', link: 'https://twitter.com/newdevonthebloc/', logo: TwitterLogo }
    ]
}