import React, { useState, useEffect } from 'react';
import styles from './Background.module.scss';

const Background = () => {
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    return (
        <div id="site-bg" className={styles['background']}>
            <div className={styles['parallax-moon']} style={{ transform: `translate(-${offsetY * 0.1}px, -${offsetY * 0.2}px) scale(${1 - offsetY * 0.001})` }}></div>
            <div className={styles['parallax-earth']} style={{ transform: `translateY(-${offsetY * 0.7}px) scale(${1 + offsetY * 0.0015}) rotateZ(${2 + offsetY * 0.03}deg)` }}></div>
            <div className={styles['stars']}></div>
            <div className={styles['twinkling']}></div>
        </div>
    )
}

export default Background;