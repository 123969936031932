import React from 'react';
import appStyles from './../App.module.scss';
import styles from './HomeSection.module.scss';

const HomeSection = () => (
    <div id="home" className={`section ${styles['home']}`}>
        <div className={`${appStyles['container']} ${styles['home-container']}`}>
            <div className={styles['home-content']}>
                <h1>Arjun Mudhaliyar</h1>
            </div>
        </div>
    </div>
)

export default HomeSection;